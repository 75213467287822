require('bootstrap-css-only');
require('./static/global.css');
require('./static/fontawesome/css/fontawesome.min.css');
require('./static/fontawesome/css/regular.min.css');
require('./static/fontawesome/css/solid.min.css');

const {
  getParameterByName,
  getPathName,
  getAnonymousId,
} = require('./src/common/utils');
const {
  UTM_CAMPAIGN_STORAGE_KEY,
  UTM_SOURCE_STORAGE_KEY,
} = require('./src/constants');

const getPageName = () => {
  const path = getPathName();
  const [, name, title] = path.split('/');
  return {
    name: name || 'home',
    title,
  };
};

const getUtmSource = () => {
  // Save utm_source in localStorage if its on the url
  const source = getParameterByName('utm_source');
  if (source) {
    return source;
  }

  // Save page name in localStorage if there is no utm_source in localStorage
  const { name } = getPageName();
  if (!localStorage.getItem(UTM_SOURCE_STORAGE_KEY)) {
    return name;
  }

  return undefined;
};

const getUtmCampaign = () => {
  // Save utm_campaign in localStorage if its on the url
  const campaign = getParameterByName('utm_campaign');
  if (campaign) {
    return campaign;
  }

  // Save page title (for blogs) in localStorage if there is no utm_campaign in localStorage
  const { title } = getPageName();
  if (!localStorage.getItem(UTM_CAMPAIGN_STORAGE_KEY) && title) {
    return title;
  }

  return undefined;
};

exports.onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await require(`intersection-observer`);
  }
};

exports.onInitialClientRender = () => {
  if (window.analytics) {
    window.analytics.ready(() => {
      // Always log Marketing Landed event
      const anonymousId = getAnonymousId();

      window.analytics.track('Marketing-Landed', {
        category: 'Marketing',
        externalId: anonymousId,
        external_id: anonymousId,
      });
    });
  }

  // Set utm_source and utm_campaign in localStorage
  // to save along with user details on sign up
  const source = getUtmSource();
  if (source) {
    localStorage.setItem(UTM_SOURCE_STORAGE_KEY, source);
  }

  const campaign = getUtmCampaign();
  if (campaign) {
    localStorage.setItem(UTM_CAMPAIGN_STORAGE_KEY, campaign);
  }
};

exports.onRouteUpdate = () => {
  if (window.analytics) {
    window.analytics.ready(() => {
      const anonymousId = getAnonymousId();

      const source = getParameterByName('utm_source');
      if (source) {
        window.analytics.track(`Source-${source}`, {
          category: 'Marketing',
          externalId: anonymousId,
          external_id: anonymousId,
        });
      }

      // Check utm campaign, and log event accordingly
      const campaign = getParameterByName('utm_campaign');
      const utm_content = getParameterByName('utm_content');

      if (campaign) {
        window.analytics.track(`Campaign-${campaign}`, {
          category: 'Marketing',
          externalId: anonymousId,
          external_id: anonymousId,
          utm_content,
        });

        if (utm_content) {
          window.analytics.track(`Content-${utm_content}`, {
            category: 'Marketing',
            externalId: anonymousId,
            external_id: anonymousId,
          });
        }
      }
    });
  }
};
