// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-adviser-jsx": () => import("./../src/pages/adviser.jsx" /* webpackChunkName: "component---src-pages-adviser-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-cheaper-ways-nz-jsx": () => import("./../src/pages/cheaper-ways-nz.jsx" /* webpackChunkName: "component---src-pages-cheaper-ways-nz-jsx" */),
  "component---src-pages-christchurch-flatmates-wanted-jsx": () => import("./../src/pages/christchurch-flatmates-wanted.jsx" /* webpackChunkName: "component---src-pages-christchurch-flatmates-wanted-jsx" */),
  "component---src-pages-consent-jsx": () => import("./../src/pages/consent.jsx" /* webpackChunkName: "component---src-pages-consent-jsx" */),
  "component---src-pages-faqs-jsx": () => import("./../src/pages/faqs.jsx" /* webpackChunkName: "component---src-pages-faqs-jsx" */),
  "component---src-pages-first-home-buyer-jsx": () => import("./../src/pages/first-home-buyer.jsx" /* webpackChunkName: "component---src-pages-first-home-buyer-jsx" */),
  "component---src-pages-get-started-jsx": () => import("./../src/pages/get-started.jsx" /* webpackChunkName: "component---src-pages-get-started-jsx" */),
  "component---src-pages-guides-insurance-adviser-jsx": () => import("./../src/pages/guides/insurance-adviser.jsx" /* webpackChunkName: "component---src-pages-guides-insurance-adviser-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-life-insurance-jsx": () => import("./../src/pages/life-insurance.jsx" /* webpackChunkName: "component---src-pages-life-insurance-jsx" */),
  "component---src-pages-market-scan-index-jsx": () => import("./../src/pages/market-scan/index.jsx" /* webpackChunkName: "component---src-pages-market-scan-index-jsx" */),
  "component---src-pages-market-scan-life-insurance-jsx": () => import("./../src/pages/market-scan/life-insurance.jsx" /* webpackChunkName: "component---src-pages-market-scan-life-insurance-jsx" */),
  "component---src-pages-otago-flatmates-wanted-jsx": () => import("./../src/pages/otago-flatmates-wanted.jsx" /* webpackChunkName: "component---src-pages-otago-flatmates-wanted-jsx" */),
  "component---src-pages-premium-jsx": () => import("./../src/pages/premium.jsx" /* webpackChunkName: "component---src-pages-premium-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-q-4-e-js": () => import("./../src/pages/q4e.js" /* webpackChunkName: "component---src-pages-q-4-e-js" */),
  "component---src-pages-quashed-for-employers-jsx": () => import("./../src/pages/quashed-for-employers.jsx" /* webpackChunkName: "component---src-pages-quashed-for-employers-jsx" */),
  "component---src-pages-referral-js": () => import("./../src/pages/referral.js" /* webpackChunkName: "component---src-pages-referral-js" */),
  "component---src-pages-terms-jsx": () => import("./../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-terms-q-4-a-jsx": () => import("./../src/pages/terms/q4a.jsx" /* webpackChunkName: "component---src-pages-terms-q-4-a-jsx" */),
  "component---src-pages-why-life-insurance-jsx": () => import("./../src/pages/why-life-insurance.jsx" /* webpackChunkName: "component---src-pages-why-life-insurance-jsx" */),
  "component---src-templates-v-3-post-jsx": () => import("./../src/templates/v3/post.jsx" /* webpackChunkName: "component---src-templates-v-3-post-jsx" */),
  "component---src-templates-v-3-wiki-jsx": () => import("./../src/templates/v3/wiki.jsx" /* webpackChunkName: "component---src-templates-v-3-wiki-jsx" */)
}

